<template>
	<div class="menu-container" :style="menuStyle">
		<div class="menu-banner" :class="{heatmapOpacity: menusStore.heatMap, menuOnly: menusStore.orderMode === 'none'}">
			<img class="image-multi-menu" :class="{hidden: hiddenMultiMenu, hiding: hidingMultiMenu, bigger: startAnimation}" :src="bannerPicture" :alt="getString('menu_banner')">
			<img class="image-menu" :class="{hidden: hiddenMenu, hiding: hidingMenu, opaque: startAnimation}" :src="imageMenu" :alt="getString('menu_banner')">
		</div>
		<div class="menu-container-scroll" ref="menu-container-scroll" id="menu-container-scroll">
			<MenuTopBar />
			<BannerRoundedCorners />
			<div class="menu-container-scroll-content">
				<MenuHeader />
				<MenuBody ref="body-child" />
				<MenuFooter />
				<div class="order-here-banner" v-if="windowWidth <= 1024 && menusStore.orderMode !== 'none'" :class="{hidden: cartStore.itemsQuantity > 0 || menusStore.hideOrderHere}" @click="$refs['popup-order-here'].toggleStatus(true)">
					<div class="order-here-message">{{ getString('order_here') }}</div>
				</div>
			</div>
			<div class="payment-mobile-container" v-if="menusStore.orderMode !== 'none' && windowWidth <= 1024" :class="{hidden: cartStore.itemsQuantity <= 0, explode: cartButtonExplosion}">
				<div class="payment-mobile" @click="openCart">
					<div class="payment-mobile-text-container"><div class="payment-mobile-text">{{ cartStore.itemsQuantity }}</div></div>
					<div class="payment-mobile-icon" v-html="getIcon('iconShop')"></div>
				</div>
			</div>
		</div>
		<MenuPopup
			id="popup-order-here"
			ref="popup-order-here"
			:z-index="10000"
			:buttons="[{text: getString('ok'), callback: this.orderHereDismiss}]"
			:title="getString('order_here_now')">
			<div class="order-here-explain-container">
				<div class="container-info-step">
					<div class="step-info">1</div>
					<p class="step-info-p translate">{{ getString('choose_products_explain') }}</p>
				</div>
				<div class="container-info-step">
					<div class="step-info">2</div>
					<p class="step-info-p translate">{{ getString('press_button_cart') }}</p>
				</div>
				<div class="container-info-step">
					<div class="step-info">3</div>
					<p class="step-info-p translate">{{ getString('proceed_to_payment') }}</p>
				</div>
			</div>
		</MenuPopup>
		<MenuPopup
			ref="popup-request-email"
			:z-index="10000"
			:title="getString('email_required')"
			:buttons="[{text: getString('cancel'), negative: true, callback: this.popupEmailCancel}, {text: getString('confirm'), callback: this.popupEmailConfirm}]" >
			<div class="email-popup-body">
				<p class="email-popup-explain">{{ getString('email_required_explain') }}</p>
				<MenuTextInput ref="popup-email-input" type="email" :placeholder="getString('email')" @changed-text="v => cartStore.customerEmail = v" />
			</div>
		</MenuPopup>
		<AppBottomSheet id="order-bottom-sheet" :forceWidth="bottomSheetWidth" :active="orderMobileSheetActive" @toggling-popup="togglingPopupCart">
            <OrderContainer mobile v-if="orderMobileSheetActiveDelayed" />
		</AppBottomSheet>
        <AppBottomSheet id="cross-bottom-sheet" :z-index="1002" :forceWidth="bottomSheetWidth" :active="menusStore.business.options.cross_selling_popup && crossSheetActive" @toggling-popup="togglingPopupCross">
            <CrossSellingContainer v-if="crossSheetActiveDelayed" @close="crossSheetActive = false" />
        </AppBottomSheet>
		<AppBottomSheet id="pay-table-bottom-sheet" :forceWidth="bottomSheetWidth" :active="cartStore.payTableSheetActive" @toggling-popup="togglingSheetPayTable">
			<PayTable v-if="cartStore.payTableSheetActive" />
		</AppBottomSheet>
	</div>
</template>

<script>
import MenuBody from "@/new-menu/components/MenuBody.vue";
import MenuFooter from "@/new-menu/components/MenuFooter.vue";
import MenuHeader from "@/new-menu/components/MenuHeader.vue";
import BannerRoundedCorners from "@/new-menu/components/BannerRoundedCorners.vue";
import AppChevronIcon from "@/shared/components/AppChevronIcon.vue";
import {useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import MenuTopBar from "@/new-menu/components/MenuTopBar.vue";
import {useLanguageStore, useNotificationStore, useSvgStore} from "@/shared/stores";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import MenuPopup from "@/new-menu/components/generic/MenuPopup.vue";
import {isTextColorDark} from "@/shared/helpers/ui";
import {validateEmail} from "@/shared/helpers/strings";
import AppBottomSheet from "@/shared/components/AppBottomSheet.vue";
import OrderContainer from "@/new-menu/components/order/OrderContainer.vue";
import {formatDateTimeWithSeconds} from "@/shared/helpers/dates";
import CrossSellingContainer from "@/new-menu/components/modals/CrossSellingContainer.vue";
import PayTable from "@/new-menu/components/pay/PayTable.vue";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		let notificationStore = useNotificationStore();
		let svgStore = useSvgStore();
		let evsStore = useEvsStore();
		return {cartStore, menusStore, getString: languageStore.getString, notificationStore, getIcon: svgStore.get, evsStore}
	},
	name: "MenuView",
	mixins: [WindowWidth],
	components: {
		PayTable,
        CrossSellingContainer,
        AppBottomSheet, MenuTopBar, MenuTextInput, AppChevronIcon, BannerRoundedCorners, MenuHeader, MenuFooter, MenuBody, MenuPopup, OrderContainer},
	data() {
		return {
			currentScroll: 0,
			minScroll: this.menusStore.imageSize - 65,
			maxScroll: this.menusStore.imageSize - 25,
			hiddenMultiMenu: false,
			hidingMultiMenu: false,
			hiddenMenu: true,
			hidingMenu: true,
			startAnimation: true,
			cartButtonExplosion: false,
			crossSheetActive: false,
            crossSheetActiveDelayed: this.crossSheetActive,
			orderMobileSheetActiveDelayed: this.orderMobileSheetActive,
		}
	},
	computed: {
		orderMobileSheetActive() {
			return this.cartStore.openCart && this.menusStore.orderMode !== 'none' && this.windowWidth <= 1024;
		},
		bottomSheetWidth() {
			if (this.windowWidth < 550) {
				return "100%";
			} else {
				return "550px";
			}
		},
		menuStyle() {
			let bg1 = this.menusStore.theme.bg_1;
			let isLight = this.menusStore.theme.light === "1";
			let imageSize = this.menusStore.imageSize;
			let scrollPercentage = this.getPercentScroll();
			let alphaHex50 = this.alphaToHex(scrollPercentage*0.5);
			let alphaHex20 = this.alphaToHex(scrollPercentage*0.2);
			let themeBg1Animated = "--theme-bg1-color-animated: #" + bg1 + alphaHex50 + ";";
			let blurAnimated = "--blur-animated: " + (scrollPercentage * 6) + "px;";
			let boxShadowColor = "--theme-dark-animated: #000000" + (isLight ? alphaHex20 : alphaHex50) + ";";
			let opacityAnimated = "--opacity-animated: " + scrollPercentage + ";";
			let opacityAnimatedReverse = "--opacity-animated-reverse: " + (1 - scrollPercentage) + ";";
			let imageSizeVar = "--image-size: " + imageSize + "px;";
			let businessColorOverride = "";
			if(this.menusStore.selectedMenu && this.menusStore.selectedMenu.color) {
				let color = this.menusStore.selectedMenu.color;
				let textColor = isTextColorDark(color) ? "#000000" : "#FFFFFF";
				businessColorOverride = "--business-color: #" + color + ";";
				businessColorOverride += '--business-color-alpha20: #'+color+"32;";
				businessColorOverride += '--business-color-alpha40: #'+color+"64;";
				businessColorOverride += '--business-color-alpha60: #'+color+"96;";
				businessColorOverride += '--business-color-alpha80: #'+color+"CC;";
				businessColorOverride += '--business-text-color: '+textColor+';';
			}
			return themeBg1Animated + blurAnimated + boxShadowColor + opacityAnimated + opacityAnimatedReverse + imageSizeVar + businessColorOverride;
		},
		imageMenu() {
			if(this.menusStore.selectedMenu && this.menusStore.selectedMenu.picture) {
				return this.menusStore.selectedMenu.picture;
			}
			return this.menusStore.business.picture;
		},
		bannerPicture() {
			if(this.menusStore.business.picture) {
				return this.menusStore.business.picture;
			} else {
				let presetUrl = "https://img.qromo.io/img/presets";
				switch (this.menusStore.business.type) {
					case "restaurant":
						return presetUrl+"/new-preset-restaurant-1.jpg";
					case "bar":
						return presetUrl+"/new-preset-bar-1.jpg";
					case "beach":
						return presetUrl+"/new-preset-beach-1.jpg";
					case "other":
						return presetUrl+"/new-preset-other-1.jpg";
				}
			}
		}
	},
	methods: {
		openCart() {
            if(this.menusStore.business.options.cross_selling_popup && !sessionStorage.getItem("cross_selling_popup")) {
                let suggestedItems = this.cartStore.getSuggestedItems();
                if(suggestedItems.length > 0) {
                    this.crossSheetActive = true;
                    sessionStorage.setItem("cross_selling_popup", "1");
                } else {
                    this.cartStore.openCart = true;
                }
			} else {
                this.cartStore.openCart = true;
			}
            this.evsStore.enqueueEvent("click", "order-summary-button", this.cartStore.grandTotal);
		},
		togglingPopupCart(isOpen) {
			if(!isOpen) {
				this.cartStore.openCart = false;
                this.$nextTick(() => {
                    this.orderMobileSheetActiveDelayed = false;
                });
			} else {
                this.orderMobileSheetActiveDelayed = true;
            }
		},
		togglingSheetPayTable(isOpen) {
			if(!isOpen) {
				this.cartStore.payTableSheetActive = false;
			}
		},
        togglingPopupCross(isOpen) {
			if(!isOpen) {
				this.crossSheetActive = false;
                setTimeout(() => {
                    this.crossSheetActiveDelayed = false;
                }, 500);
                if(this.menusStore.business.options.cross_selling_popup) {
                    setTimeout(() => {
                        this.cartStore.openCart = true;
                    }, 530);
				}
			} else {
                this.crossSheetActiveDelayed = true;
            }
		},
		orderHereDismiss() {
			this.menusStore.hideOrderHere = true;
			localStorage.setItem("dismissOrderHere", "1");
			this.$refs["popup-order-here"].toggleStatus(false);
		},
		popupEmailConfirm() {
			if(this.cartStore.customerEmail && validateEmail(this.cartStore.customerEmail)) {
				this.cartStore.emailPopupCallback && this.cartStore.emailPopupCallback(true);
				this.$refs["popup-request-email"].toggleStatus(false);
			} else {
				this.notificationStore.showNotification("invalid_email");
				this.$refs["popup-email-input"].focus();
			}
		},
		popupEmailCancel() {
			this.$refs["popup-request-email"].toggleStatus(false);
			this.cartStore.emailPopupCallback && this.cartStore.emailPopupCallback(false);
		},
		getPercentScroll() {
			if(this.currentScroll > this.maxScroll) return 1;
			if(this.currentScroll < this.minScroll) return 0;
			return (this.currentScroll - this.minScroll) / (this.maxScroll - this.minScroll);
		},
		alphaToHex(alpha) {
			let hex = Math.round(alpha * 255).toString(16);
			if(hex.length === 1) hex = "0" + hex;
			return hex;
		},
		handleScroll() {
			this.currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
			let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

            if (window.innerHeight + this.currentScroll >= scrollHeight - 100) {
                let event = this.evsStore.updatableEvents.find((event) => { return event.name === 'scroll' && event.action === 'reached-page-bottom' && event.hasOwnProperty("menu_id") && event.menu_id === this.menusStore.selectedMenuId; });
                let eventFound = !!event;
                if(!eventFound) {
                    event = {
                        name: 'scroll',
                        action: 'reached-page-bottom',
						value: null,
                        menu_id: this.menusStore.selectedMenuId,
                        date: formatDateTimeWithSeconds(new Date()),
                        toUpdate: true
                    };
                    this.evsStore.updatableEvents.push(event);
                }
            }
		},
		fromMultiToMenuImageTransition() {
			this.hidingMultiMenu = true;
			setTimeout(() => {
				this.hiddenMenu = false;
				requestAnimationFrame(() => {
					requestAnimationFrame(() => {
						this.hidingMenu = false;
					});
				});
				setTimeout(() => {
					this.hiddenMultiMenu = true;
				}, 200);
			}, 200);
		},
		fromMenuToMultiImageTransition() {
			this.hidingMenu = true;
			setTimeout(() => {
				this.hiddenMultiMenu = false;
				requestAnimationFrame(() => {
					requestAnimationFrame(() => {
						this.hidingMultiMenu = false;
					});
				});
				setTimeout(() => {
					this.hidingMenu = true;
				}, 200);
			}, 200);
		},
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll);
		setTimeout(() => {
			this.startAnimation = false;
		}, 500);

		if(this.windowWidth > 1024) {
			this.cartStore.openCart = true;
		}

		setTimeout(() => {
			if(this.cartStore.itemsQuantity === 0) {
				let dismissed = localStorage.getItem("dismissOrderHere");
				if(!dismissed) {
					this.menusStore.hideOrderHere = false;
				}
			}
		}, 9000);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	watch: {
		"menusStore.selectedMenuId": function(newVal, oldVal) {
			if(newVal !== oldVal) {
				if(newVal) {
					if(this.menusStore.selectedMenu && this.menusStore.selectedMenu.picture) {
						this.fromMultiToMenuImageTransition();
					}
				}
			}
		},
		"menusStore.isDeselectingMenu": function(newVal, oldVal) {
			if(newVal !== oldVal) {
				if(newVal) {
					if(this.menusStore.selectedMenu && this.menusStore.selectedMenu.picture) {
						this.fromMenuToMultiImageTransition();
					}
				}
			}
		},
		windowWidth: function(newVal, oldVal) {
			if(newVal !== oldVal) {
				if(!this.cartStore.openCart && newVal > 1024) {
					this.cartStore.openCart = true;
				} else if(this.cartStore.openCart && newVal <= 1024 && oldVal > 1024) {
					this.cartStore.openCart = false;
				}
			}
		},
		"cartStore.showEmailPopup": function(newVal, oldVal) {
			if(newVal !== oldVal) {
				if(newVal) {
					this.$refs["popup-request-email"].toggleStatus(true);
					this.cartStore.showEmailPopup = false;
				}
			}
		},
		"cartStore.itemsQuantity": function(newVal, oldVal) {
			if(newVal !== oldVal) {
				if(newVal > oldVal) {
					this.cartButtonExplosion = true;
					setTimeout(() => {
						this.cartButtonExplosion = false;
					}, 220);
				}
			}
		}
	}
}
</script>


<style lang="scss">
#popup-order-here {
	.popup-container {
		max-width: 400px;
	}
}
#order-bottom-sheet, #cross-bottom-sheet, #pay-table-bottom-sheet {
	background-color: var(--theme-txt1-alpha60-color);
	backdrop-filter: blur(6px);
	-webkit-backdrop-filter: blur(6px);
	transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out, backdrop-filter 0.5s ease 0.3s, -webkit-backdrop-filter 0.5s ease 0.3s;

	&.hidden {
		opacity: 0;
		backdrop-filter: blur(0px);
		-webkit-backdrop-filter: blur(0px);
		background-color: var(--theme-txt1-alpha60-color);
	}

	.bottom-sheet {
		padding: 0;
		background-color: var(--theme-bg2-color);
		border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		max-height: calc(95% - 15px);

		.bottom-sheet-measurer {
			border-radius: var(--rounded-corner-l) var(--rounded-corner-l) 0 0;
		}
	}
}
</style>
<style scoped lang="scss">
.menu-container {
	max-width: 1080px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	width: calc((100% / 3) * 2);

	@media (max-width: 1024px) {
		width: 100%;
	}

	.menu-banner {
		height: var(--image-size);
		background-color: var(--theme-bg2-color);
		width: calc((100% / 3) * 2);
		max-width: 1080px;
		position: fixed;
		top: 0;
		right: calc((100% / 3));
		overflow: hidden;

		&.menuOnly {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		@media (max-width: 1024px) {
			width: 100%;
			right: 0;
		}

		img {
			object-fit: cover;
			transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 1;

			&.hidden {
				display: none;
			}
			&.hiding {
				opacity: 0;
				transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;
				transform: scale(1.05);
			}
			&.bigger {
				transform: scale(1.05);
			}
			&.opaque {
				display: block;
				opacity: 1;
			}
		}
	}

	.menu-container-scroll {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		z-index: 100;

		.menu-container-scroll-content {
			background-color: var(--theme-bg1-color);
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;

			.order-here-banner {
				position: fixed;
				bottom: 0;
				left: 0;
				width: 100%;
				background-color: var(--business-color);
				display: flex;
				justify-content: center;
				align-items: center;
				z-index: 1000;
				box-shadow: var(--theme-box-shadow);
				padding: 20px;
				border-top-left-radius: var(--rounded-corner);
				border-top-right-radius: var(--rounded-corner);
				cursor: pointer;
				transition: background-color 0.2s ease, transform 0.22s ease, opacity 0.3s ease;

				.order-here-message {
					font-size: var(--font-size-l);
					line-height: var(--line-height-l);
					color: var(--business-text-color);
				}

				&.hidden {
					pointer-events: none;
					transform: translateY(100px);
					opacity: 0;
				}

			}
		}

		.payment-mobile-container {
			display: flex;
			justify-content: center;
			align-items: center;
			position: fixed;
			z-index: 1000;
			bottom: var(--padding-vertical);
			right: var(--padding-horizontal);
			transform: translateX(0);
			opacity: 1;
			transition: transform 0.22s ease, opacity 0.3s ease;

			&.hidden {
				transform: translateX(200px);
				opacity: 0;
				pointer-events: none;
			}

			&.explode {
				transform: translateX(0) scale(1.07);
			}

			.payment-mobile {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: var(--rounded-corner);
				padding: 11px var(--padding-horizontal-s);
				background-color: var(--business-color);
				box-shadow: var(--theme-box-shadow);
				cursor: pointer;
				transition: background-color 0.2s ease;
				border: 2px solid var(--business-text-color);
				min-width: 100px;

				.payment-mobile-text-container {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: var(--theme-bg1-color);
					box-shadow: inset var(--theme-box-shadow);
					border-radius: var(--rounded-corner-s);
					padding: 4px 4px;
					margin-right: 8px;
					min-width: 32px;

					.payment-mobile-text {
						font-size: var(--font-size-l);
						line-height: var(--line-height-l);
						color: var(--business-color);
					}
				}

				.payment-mobile-icon {
					width: 26px;
					height: 26px;
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 2px;

					&:deep(svg) {
						width: 100%;
						height: 100%;

						.fill {
							fill: var(--business-text-color);
						}

						.stroke {
							stroke: var(--business-text-color);
						}

					}
				}
			}

		}

	}

}

.email-popup-body {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 0 20px;

	.email-popup-explain {
		font-size: var(--font-size);
		line-height: var(--line-height);
		color: var(--theme-txt2-color);
		text-align: center;
		margin-bottom: var(--padding-vertical);
	}

	.menu-text-input {
		&:deep(input) {
			min-height: 38px;
			width: 220px;
		}
	}
}

.order-here-explain-container {

	.container-info-step {
		display: flex;
		align-items: flex-start;
		width: 100%;
		max-width: 500px;
		margin: 20px auto;

		.step-info {
			border: 2px solid var(--business-color);
			border-radius: var(--rounded-corner);
			padding: 0 6px;
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--business-color);
			font-weight: 700;
		}
		.step-info-p {
			margin: 0 0 0 10px;
			text-align: left;
			color: var(--theme-txt1-color);
		}
	}

}
</style>