<template>
	<div class="menu-category" :style="categoryStyle">
		<div class="category-head" ref="menu-category-head" :class="{highlighted: category.featured, expandable: category.dropdown, open: category.isOpen, notRelevant: allergensFiltered || searchFiltered, isCollapsed}" @click.stop="toggleCategory" :id="'cat-head-'+category.menu_category_id">
			<canvas v-if="menusStore.heatMapData" ref="heat-map-canvas" style="position: absolute; top: 0; left: 0;z-index: 1000;"></canvas>
			<div class="category-title" :class="{heatmapOpacity: menusStore.heatMap}">
				<div class="title" v-html="categoryNameSanitized"></div>
				<div class="expand-icon"><AppChevronIcon :direction="category.isOpen ? 'up' : 'down'" color="var(--category-text-color)" /></div>
			</div>
			<div class="category-banner" :class="{noPicture: !category.picture, heatmapOpacity: menusStore.heatMap}">
				<img v-if="category.picture" :src="category.picture" :alt="category.nameTranslation">
				<div class="items-counter-container" :class="{shown: categoryQuantity > 0}">
					<div class="items-counter">{{ categoryQuantity }}</div>
				</div>
				<div class="expand-icon"><AppChevronIcon :direction="category.isOpen ? 'up' : 'down'" color="var(--category-text-color)" /></div>
			</div>
		</div>
		<AppDropdown :collapsed="isCollapsed">
			<div class="category-body">
				<div class="category-description" v-if="!!category.description">{{ categoryDescriptionSanitized }}</div>
				<div class="items-container" :class="{carouselItems: carousel}" v-if="category.isOpenDelayed || !category.dropdown" :id="'cat-items-'+category.menu_category_id">
					<MenuCategoryItem :no-picture="category.noItemsPictures" :carousel="carousel" v-for="item in category.items" :key="item.menu_item_id" :item-id="item.menu_item_id" :category-id="category.menu_category_id" :grid="category.grid_mode" />
				</div>
			</div>
		</AppDropdown>
	</div>
</template>

<script>
import AppChevronIcon from "@/shared/components/AppChevronIcon.vue";
import MenuCategoryItem from "@/new-menu/components/MenuCategoryItem.vue";
import AppDropdown from "@/shared/components/AppDropdown.vue";
import {isTextColorDark} from "@/shared/helpers/ui";
import {useCartStore, useEvsStore, useMenusStore} from "@/new-menu/stores";
import WindowWidth from "@/console-new/mixins/WindowWidth";
import {drawHeatMapClusters} from "@/new-menu/helpers/heatmap";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
        let evsStore = useEvsStore();
		return {cartStore, menusStore, evsStore}
	},
	name: "MenuCategory",
	components: {AppDropdown, MenuCategoryItem, AppChevronIcon},
	mixins: [WindowWidth],
	data() {
		return {
		}
	},
	props: {
		carousel: {
			type: Boolean,
			default: false
		},
		categoryId: {
			type: Number,
			required: true
		}
	},
	computed: {
		category() {
			return this.menusStore.getCategoryById(this.categoryId);
		},
		isCollapsed() {
			if(this.searchFiltered) {
				return true;
			}

			if(this.allergensFiltered) {
				return true;
			}

			return this.category.dropdown && !this.category.isOpen;
		},
		categoryNameSanitized() {
			if(this.menusStore.search === null || this.menusStore.search === "") {
				return this.$sanitize(this.category.nameTranslation);
			} else {
				return this.getSanitizedStringFromSearch(this.category.nameTranslation);
			}
		},
		categoryDescriptionSanitized() {
			return this.category.descriptionTranslation ? this.$sanitize(this.category.descriptionTranslation) : null;
		},
		categoryStyle() {
			let catColor = this.category.color ? this.category.color : this.menusStore.business.color;
			let catColorText = isTextColorDark(catColor) ? "#000000" : "#ffffff";
			let categoryColor = "--category-color: #" + catColor + ";";
			let categoryColorAlpha40 = "--category-color-alpha40: #" + catColor + "64;";
			let categoryColorAlpha60 = "--category-color-alpha60: #" + catColor + "96;";
			let textColor = "--category-text-color: " + catColorText + ";";
			return categoryColor + categoryColorAlpha40 + textColor + categoryColorAlpha60;
		},
		categoryQuantity() {
			return this.cartStore.getCategoryQuantity(this.category);
		},
		allergensFiltered() {
			if(this.menusStore.allergensFilterNum === 0) {
				return false;
			}
			return this.category.items.every(item => {
				return item.allergens.length !== 0 && item.allergens.some(allergen => !allergen.active);
			});
		},
		searchFiltered() {
			if(!this.menusStore.search) {
				return false;
			}
			return !this.category.searched;
		}
	},
	methods: {
		getSanitizedStringFromSearch(str) {
			let search = this.menusStore.search.toLowerCase();
			let lowerStr = str.toLowerCase();
			let index = lowerStr.indexOf(search);
			if(index !== -1) {
				let start = str.substring(0, index);
				let end = str.substring(index + search.length);
				let searchPart = str.substring(index, index + search.length);
				return this.$sanitize(start) + "<span class='highlight'>" + this.$sanitize(searchPart) + "</span>" + this.$sanitize(end);
			} else {
				return this.$sanitize(str);
			}
		},
		openCategory(category = this.category) {

			let originalMenu = this.menusStore.menus.find(menu => menu.menu_id === category.menu_id);
			let originalCategory = originalMenu ? originalMenu.categories.find(cat => cat.menu_category_id === category.menu_category_id) : null;
			if(originalCategory) {
				this.menusStore.scrollToCategory(category);
				originalCategory.isOpenDelayed = !originalCategory.isOpen;
				requestAnimationFrame(() => {
					requestAnimationFrame(() => {
						originalCategory.isOpen = !originalCategory.isOpen;
						originalCategory.isOpenDelayed = originalCategory.isOpen;
					});
				});
                this.evsStore.enqueueEvent('click', 'open-category', this.categoryId);
			}
		},
		closeCategory(category = this.category) {

			let originalMenu = this.menusStore.menus.find(menu => menu.menu_id === category.menu_id);
			let originalCategory = originalMenu ? originalMenu.categories.find(cat => cat.menu_category_id === category.menu_category_id) : null;
			if(originalCategory) {
				originalCategory.isOpen = !originalCategory.isOpen;
				setTimeout(() => {
					originalCategory.isOpenDelayed = originalCategory.isOpen;
				}, 550);
			}
		},
		toggleCategory(event) {
            this.clickEv(event);
			if(this.allergensFiltered) {
				this.menusStore.filteredCategoryToShow = this.category.menu_category_id;
				return;
			}
			if(this.searchFiltered) {
				this.menusStore.searchedCategoryToShow = this.category.menu_category_id;
				return;
			}
			if(this.category.dropdown) {
				if(!this.category.isOpen) {

					this.openCategory();
					if(this.menusStore.selectedMenu) {
						this.menusStore.selectedMenu.categories.forEach(category => {
							if(category.menu_category_id !== this.category.menu_category_id && category.isOpen && category.dropdown) {
								this.closeCategory(category);
							}
						})
					}

				} else {
					this.closeCategory();
				}
			}
		},
        clickEv(event) {

            if(event) {
                let itemElement = this.$refs['menu-category-head'];
                let rect = itemElement.getBoundingClientRect();
                let x = event.clientX - rect.left;
                let y = event.clientY - rect.top;
                let xPercent = (x / rect.width) * 100;
                let yPercent = (y / rect.height) * 100;

                this.evsStore.enqueueEvent('click', 'category', JSON.stringify({
                    id: Number(this.categoryId),
                    x: Number(xPercent.toFixed(2)),
                    y: Number(yPercent.toFixed(2)),
                }));
            }

        },
		drawHeatMap() {
			const container = this.$refs['menu-category-head'];
			const canvas = this.$refs['heat-map-canvas'];
			if(canvas) {
				const ctx = canvas.getContext('2d');

				const rect = container.getBoundingClientRect();
				canvas.width = rect.width;
				canvas.height = rect.height;

				let categoryData = this.menusStore.heatMapData && this.menusStore.heatMapData.hasOwnProperty("c"+this.categoryId) ? this.menusStore.heatMapData["c"+this.categoryId] : null;
				if(categoryData && this.menusStore.heatMapData.hasOwnProperty("maxPoints")) {
					drawHeatMapClusters(ctx, categoryData, this.menusStore.heatMapData.maxPoints, rect);
				}
			}

		}
	},
	mounted() {
		if(this.menusStore.heatMapData) {
			this.drawHeatMap();
		}
	},
	watch: {
		'menusStore.heatMapData': {
			handler(newVal) {
				if(newVal) {
                    this.$nextTick(() => {
                        this.drawHeatMap();
                    });
				}
			},
			deep: true
		},
		windowWidth() {
			if(this.menusStore.heatMapData) {
				this.drawHeatMap();
			}
		}
	}
}
</script>

<style scoped lang="scss">
.menu-category {
	display: flex;
	flex-direction: column;
	width: 100%;

	&:first-child {
		.category-head {
			scroll-margin-top: 0 !important;
		}
	}

	.category-head {
		display: flex;
		flex-direction: row;
		width: 100%;
		position: relative;
		border-radius: var(--rounded-corner);
		opacity: 1;
		transition: opacity 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
		scroll-margin-top: 88px;

		canvas {
			border-radius: var(--rounded-corner);
		}

		.category-title {
			width: calc((100% / 3) * 2);
			background-color: var(--category-color);
			padding: var(--padding-vertical) var(--padding-horizontal-s);
			border-top-left-radius: var(--rounded-corner);
			border-bottom-left-radius: var(--rounded-corner);
			display: flex;
			justify-content: space-between;
			align-items: center;
			min-height: 48px;
			position: relative;
			z-index: 10;

			.title {
				font-size: var(--font-size-xxl);
				line-height: var(--line-height-xxl);
				color: var(--category-text-color);
				@include truncateString(3);

				&:deep(.highlight) {
					font-size: var(--font-size-xxl);
					line-height: var(--line-height-xxl);
					color: var(--category-text-color);
					background: var(--business-color-alpha20);
					position: relative;

					&:after {
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 2px;
						background-color: var(--business-color);
						border-radius: 1px;
					}
				}

			}

			.expand-icon {
				display: none;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
			}


			&:before {
				content: '';
				height: 100%;
				display: block;
				background: inherit;
				position: absolute;
				top: 0;
				right: -17px;
				width: 36px;
				border-radius: var(--rounded-corner);
				transform: skewX(-10deg) translateX(0px);
				z-index: -1;
			}

			&.heatmapOpacity {
				&:before {
					display: none;
				}
			}

		}

		.category-banner {
			width: calc(100% / 3);
			height: auto;
			min-height: 40px;
			border-bottom-right-radius: var(--rounded-corner);
			border-top-right-radius: var(--rounded-corner);
			position: relative;

			&.noPicture {
				background-color: var(--category-color-alpha60);
			}

			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-bottom-right-radius: var(--rounded-corner);
				border-top-right-radius: var(--rounded-corner);
			}

			.items-counter-container {
				position: absolute;
				bottom: var(--padding-vertical);
				right: 0;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				transition: transform 0.25s ease;
				transform: scaleX(0);
				transform-origin: right;

				&.shown {
					transform: scaleX(1);
				}

				.items-counter {
					background-color: var(--category-color);
					color: var(--category-text-color);
					position: relative;
					z-index: 10;
					text-align: right;
					padding: 4px 8px 4px 0;
					min-width: 20px;
					font-size: 14px;
					line-height: 16px;
					font-weight: 700;

					&:before {
						content: '';
						height: 100%;
						display: block;
						background: inherit;
						position: absolute;
						top: 0;
						left: -7px;
						width: 16px;
						border-radius: var(--rounded-corner-s);
						transform: skewX(-10deg) translateX(0px);
						z-index: -1;
					}
				}

			}

			.expand-icon {
				display: none;
				justify-content: center;
				align-items: center;
				flex-shrink: 0;
				position: absolute;
				bottom: var(--padding-vertical);
				right: 7px;
				background-color: var(--category-color-alpha40);
				padding: 5px;
				border-radius: var(--rounded-corner);
				backdrop-filter: blur(4px);
				-webkit-backdrop-filter: blur(4px);
			}
		}

		&.expandable {
			cursor: pointer;
			box-shadow: var(--theme-box-shadow);

			.category-title {

			}

			.category-banner {
				min-height: 80px;

				.expand-icon {
					display: flex;
				}

				.items-counter-container {
					bottom: calc((var(--padding-vertical) * 2) + 23px);
				}
			}

			&.open {
				box-shadow: var(--theme-box-shadow-null);
			}
		}

		&.highlighted {
			flex-direction: column;

			.category-title {
				width: 100%;
				order: 2;
				border-bottom-right-radius: var(--rounded-corner);
				border-top-left-radius: 0;

				&:before {
					display: none;
				}
			}

			.category-banner {
				width: 100%;
				height: 80px;
				order: 1;
				border-top-left-radius: var(--rounded-corner);
				border-bottom-right-radius: 0;

				img {
					position: static;
					border-top-left-radius: var(--rounded-corner);
					border-bottom-right-radius: 0;
				}
			}

			&.expandable {
				.category-title {
					.expand-icon {
						display: flex;
					}
				}

				.category-banner {
					height: 110px;

					.expand-icon {
						display: none;
					}

					.items-counter-container {
						bottom: var(--padding-vertical);
					}
				}
			}
		}

		&.notRelevant {
			opacity: 0.5;
			box-shadow: var(--theme-box-shadow-null);
		}
	}

	&:deep(.dropdown) {
		transition: max-height 0.5s cubic-bezier(.64,.01,.29,1.44), opacity 0.5s cubic-bezier(0.66, 0.12, 0.21, 1) 0.1s, visibility 0.3s ease;

		.dropdown-content {
			background-color: unset !important;
			margin-top: 0 !important;
			padding: 0 !important;
		}
	}

	.category-body {

		.category-description {
			padding: var(--padding-vertical) var(--padding-horizontal-s);
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
			border-bottom: 2px solid var(--theme-bg2-color);
		}

		.items-container {
			display: flex;
			align-items: flex-start;
			flex-wrap: wrap;
			gap: var(--padding-vertical);
			padding: var(--padding-vertical) 0;

			&.carouselItems {
				flex-wrap: nowrap;
				overflow-x: auto;
				overscroll-behavior: unset;
				touch-action: unset;
				text-align: left;
				@include smallScrollbar();
				scrollbar-color: var(--theme-bg2-color) var(--theme-bg1-color);
				scrollbar-width: thin;

				&::-webkit-scrollbar {
					height: 8px;
				}
				&::-webkit-scrollbar-track-piece  {
					background-color: var(--theme-bg1-color);
				}
				&::-webkit-scrollbar-thumb:horizontal {
					height: 10px;
					background-color: var(--theme-bg2-color);
				}

			}

		}
	}
}
</style>