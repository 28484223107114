<template>
	<div class="map-input-container">
		<div class="row centered">
			<MenuTextInput
				ref="text-input"
				:placeholder="placeholder"
				:value="value"
				:class="{disabled: !ready}"
				class="address-input"
			/>
		</div>
	</div>
</template>

<script>
import {useScriptStore} from "@/shared/stores";
import MenuTextInput from "@/new-menu/components/generic/MenuTextInput.vue";
import {useCartStore} from "@/new-menu/stores";

export default {
	name: "AppGoogleMapsInput",
	emits: ['changed-address'],
	components: {MenuTextInput},
	setup() {
		const scriptStore = useScriptStore();
		const cartStore = useCartStore();
		return {loadScript: scriptStore.loadScript, cartStore};
	},
	data() {
		return {
			address: null,
			autocomplete: null,
			ready: false,
			autocompleteOptions: {
				types: ["address"],
				componentRestrictions: {country: COUNTRY}
			},
			autocompleteFields: ["formatted_address", "address_components", "geometry"],
		}
	},
	props: {
		placeholder: {type: String, default: ""},
		value: {type: String, default: ""},
	},
	methods: {
		initMapsInput() {
			let input = this.$refs['text-input'].$refs['input'];
			this.autocomplete = new google.maps.places.Autocomplete(input, this.autocompleteOptions);
			this.autocomplete.setFields(this.autocompleteFields);
			this.initAutocomplete(addressData=>{
				this.$emit('changed-address', addressData);
			})
		},
		initAutocomplete(addressDataCallback) {

			google.maps.event.addListener(this.autocomplete, 'place_changed', ()=>{
		
				let place = this.autocomplete.getPlace();
		
				if(place && place.geometry) {

					let lat = place.geometry.location.lat();
					let lng = place.geometry.location.lng();
		
					let address = place.formatted_address;
					let addressData = this.addressDataFromPlace(place);

					this.address = address;
					addressDataCallback({
						address: address,
						lat: lat,
						lng: lng,
						data: addressData
					});		
				} else {
					this.address = null;
				}
		
			});
		},
		addressDataFromPlace(place) {
			let returningData = {};
			place.address_components.forEach(component => {

				if(component.types && component.types.length > 0) {
					if(component.types[0] === "administrative_area_level_1") {
						returningData.regione = component.long_name;
					} else if(component.types[0] === "administrative_area_level_2") {
						returningData.provincia_codice = component.short_name;
						returningData.provincia = component.long_name;
					} else if(component.types[0] === "administrative_area_level_3") {
						returningData.comune = component.long_name;
					} else if(component.types[0] === "route") {
						returningData.via = component.long_name;
					} else if(component.types[0] === "postal_code") {
						returningData.zip = component.long_name;
					} else if(component.types[0] === "street_number") {
						returningData.civico = component.long_name;
					}
				}

			});
			returningData.country = COUNTRY;
			return returningData;
		},
		focus() {
			this.$refs['text-input'].focus();
		}
	},
	mounted() {
		if(this.cartStore.openCart) {
			this.loadScript('maps', ()=>{
				this.ready = true;
				this.initMapsInput();
			});
		}
	},
	watch: {
		"cartStore.openCart": function(val) {
			if(val) {
				this.loadScript('maps', ()=>{
					this.ready = true;
					this.initMapsInput();
				});
			}
		}
	}
}
</script>
<style lang="scss">
.pac-container {
	border-radius: var(--rounded-corner);
	border-top: none;

	&.pac-logo:after {
		display: none;
	}

	.pac-item {
		padding: 0 8px;
		background: var(--theme-bg1-color);

		&:first-child {
			border-top-left-radius: var(--rounded-corner);
			border-top-right-radius: var(--rounded-corner);
			border-top: none;
		}

		.pac-icon-marker {
			display: none;
		}

		span {
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt1-color);
		}

		&.pac-item-selected {
			background: var(--theme-bg2-color);
		}
	}

}
</style>
<style lang="scss" scoped>
.map-input-container {
	position: relative;
	width: 100%;

	> .row {
		flex-wrap: nowrap;

		.menu-text-input {
			width: 100%;
		}
	}

}
</style>