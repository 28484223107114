<template>
	<div class="items-container">
		<OrderItem v-for="item in cartStore.cart" :item="item" :key="item.temp_id" />
		<div class="extra-cost" v-if="deliveryCosts && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('delivery_costs') }}</div>
			<div class="extra-price">{{ deliveryCosts }}</div>
		</div>
		<div class="extra-cost" v-if="takeawayCosts && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('delivery_costs') }}</div>
			<div class="extra-price">{{ takeawayCosts }}</div>
		</div>
		<div class="extra-cost" v-if="courierPrice && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('courier_costs') }}</div>
			<div class="extra-price">{{ courierPrice }}</div>
		</div>
		<div class="extra-cost" v-if="weconstudioBolloPrice && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('bollo_tax') }}</div>
			<div class="extra-price">{{ weconstudioBolloPrice }}</div>
		</div>
		<div class="extra-cost" v-if="coverChargePrice && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ menusStore.business.options.cover_charge_nameTranslation }}</div>
			<div class="extra-price">{{ coverChargePrice }}</div>
		</div>
		<div class="extra-cost" v-if="cartStore.appliedCoupons.length > 0 && !menusStore.business.options.hide_prices">
			<div class="extra-title">{{ languageStore.getString('discount') }}</div>
			<div class="extra-price">{{ "-"+languageStore.readableEurValue(cartStore.couponDiscount/100) }}</div>
		</div>
	</div>
</template>

<script>
import MenuAdders from "@/new-menu/components/order/MenuAdders.vue";
import {useCartStore, useMenusStore} from "@/new-menu/stores";
import {useLanguageStore} from "@/shared/stores";
import OrderItem from "@/new-menu/components/order/OrderItem.vue";

export default {
	setup() {
		let cartStore = useCartStore();
		let menusStore = useMenusStore();
		let languageStore = useLanguageStore();
		return {cartStore, languageStore, menusStore}
	},
	name: "OrderSectionSummary",
	components: {MenuAdders, OrderItem},
	computed: {
		deliveryCosts() {
			return this.menusStore.orderMode === 'delivery' && this.menusStore.business.options.delivery_costs ? this.languageStore.readableEurValue(this.menusStore.business.options.delivery_costs/100) : null;
		},
		takeawayCosts() {
			return this.menusStore.orderMode === 'takeaway' && this.menusStore.business.options.takeaway_costs ? this.languageStore.readableEurValue(this.menusStore.business.options.takeaway_costs/100) : null;
		},
		courierPrice() {
			return this.cartStore.courierPrice ? this.languageStore.readableEurValue(this.cartStore.courierPrice/100) : null;
		},
		weconstudioBolloPrice() {
			if(this.menusStore.orderMode === "qr") {
				if(this.menusStore.weconstudioInfo && this.menusStore.weconstudioInfo.bollo_threshold && this.menusStore.weconstudioInfo.bollo_value) {
					let bolloThreshold = parseInt(this.menusStore.weconstudioInfo.bollo_threshold);
					if(this.cartStore.totalItems >= bolloThreshold) {
						return this.languageStore.readableEurValue(parseInt(this.menusStore.weconstudioInfo.bollo_value)/100);
					}
				}
			}
			return null;
		},
		coverChargePrice() {
			if(this.menusStore.orderMode === "qr" && (this.menusStore.business.options.cover_charge_everytime || !this.cartStore.coverInfo) && this.menusStore.business.options.cover_charge !== null) {
				if(this.menusStore.business.options.cover_charge_type === 1 && this.menusStore.business.options.cover_charge) {
					return this.languageStore.readableEurValue(this.menusStore.business.options.cover_charge/100);
				} else if(this.menusStore.business.options.cover_charge_type === 0 && this.cartStore.coverChargeNum > 0) {
					return this.languageStore.readableEurValue((this.cartStore.coverChargeNum * this.menusStore.business.options.cover_charge)/100);
				}
			}
			return null;
		}
	},
	methods: {
	}
}
</script>

<style scoped lang="scss">
.items-container {
	display: flex;
	flex-direction: column;

	.extra-cost {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 10px 0;
		border-bottom: 1px solid var(--theme-bg2-color);

		.extra-title {
			font-size: var(--font-size);
			line-height: var(--line-height);
			color: var(--theme-txt1-color);
			@include truncateString();
		}
		.extra-price {
			font-size: var(--font-size-s);
			line-height: var(--line-height-s);
			color: var(--theme-txt2-color);
			margin-right: 8px;
		}
	}

	div {

		&:last-child {
			border-bottom: none;
		}

	}
}
</style>